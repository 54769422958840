import * as React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';

import { clsHelper } from '../../utilities/class-name-helper';
import { attrNames } from '../../utilities/global-constants';
import { convertProductAttributes } from '../../utilities/data-attribute-parser';

import { IPdpTabContentData } from './pdp-tab-content.data';
import { IPdpTabContentProps, IPdpTabContentResources } from './pdp-tab-content.props.autogenerated';

//==============================================================================
// INTERFACES AND CONSTANTS
//==============================================================================

type PdpTabSlots = {
    descriptionTab: React.ReactNode[];
    kitTab: React.ReactNode[];
    instructorTab: React.ReactNode[];
};

//==============================================================================
// CLASS NAME UTILITY
//==============================================================================
const BASE_CLASS = 'pdp-tab-content';
const cls = (fragment?: string) => clsHelper(BASE_CLASS, fragment);

/**
 *
 * PdpTabContent component
 * @extends {React.Component<IPdpTabContentProps<IPdpTabContentData>>}
 */
@observer
class PdpTabContent extends React.Component<IPdpTabContentProps<IPdpTabContentData>> {

    @observable private activetab: string = 'description';

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    //------------------------------------------------------
    public constructor(props: IPdpTabContentProps<IPdpTabContentData>) {
        super(props);
        this._changeTab = this._changeTab.bind(this);
    }

    //------------------------------------------------------
    // Render function
    //------------------------------------------------------
    public render(): JSX.Element | null {
        const { data, resources, slots } = this.props;

        const productAttributes = data.productSpecificationData.result;

        const formattedAttributes = convertProductAttributes(productAttributes || []);
        const kitTab = formattedAttributes[attrNames.kitContents];
        const instructorTab = formattedAttributes[attrNames.instructors];
        const useTabs = !!((instructorTab && slots.instructorTab) || (kitTab && slots.kitTab));

        return (
            <div className={BASE_CLASS}>
                {useTabs && this._renderTabs(kitTab, instructorTab, resources, slots)}
                {this._renderContent(kitTab, instructorTab, slots)}
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public _renderTabs(kitTab: any, instructorTab: any, resources: IPdpTabContentResources, slotItems: PdpTabSlots): JSX.Element {
        return (
            <div className={cls('tabs')}>
                <a
                    className={classnames(cls('tab'), 'description', {'active': this._isActive('description')})}
                    onClick={this._changeTab('description')}
                    role='button'
                >
                    {resources.pdpTabDescription}
                </a>
                {kitTab && slotItems.kitTab &&
                    <a
                        className={classnames(cls('tab'), 'kit', {'active': this._isActive('kit')})}
                        onClick={this._changeTab('kit')}
                        role='button'
                    >
                        {resources.pdpTabKit}
                    </a>
                }
                {instructorTab && slotItems.instructorTab &&
                    <a
                        className={classnames(cls('tab'), 'instructor', {'active': this._isActive('instructor')})}
                        onClick={this._changeTab('instructor')}
                        role='button'
                    >
                        {resources.pdpTabInstructor}
                    </a>
                }
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public _renderContent(kitTab: any, instructorTab: any, slotItems: PdpTabSlots): JSX.Element {
        return (
            <div className={cls('container')}>
                {slotItems.descriptionTab && this._renderSlot(slotItems.descriptionTab, classnames(cls('content'), 'description', {'active': this._isActive('description')}))}
                {kitTab && slotItems.kitTab && this._renderSlot(slotItems.kitTab, classnames(cls('content'), 'kit', {'active': this._isActive('kit')}))}
                {instructorTab && slotItems.instructorTab && this._renderSlot(slotItems.instructorTab, classnames(cls('content'), 'instructor', {'active': this._isActive('instructor')}))}
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public _renderSlot(slotItems: React.ReactNode[], slotClass?: string): JSX.Element {
        return (
            <div className={slotClass}>
                {slotItems.map((slot: React.ReactNode, index: number) => (
                    <React.Fragment key={index}>
                        {slot}
                    </React.Fragment>
                ))}
            </div>
        );
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public _changeTab = (clickedTab: string) => () => {
        this.activetab = clickedTab;
    };

    //------------------------------------------------------
    //------------------------------------------------------
    public _isActive = (tab: string): boolean => {
        if (this.activetab === tab) {
            return true;
        }

        return false;
    };
}

export default PdpTabContent;
